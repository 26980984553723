import styled from 'styled-components'

import wave from '../../../assets/images/rsvp/gold_wave.svg'
import { salmon } from '../../../styles/themeColors'

export const RSVPbannerContainer = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 400px;
  width: 100%;
  & .instructions {
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    width: 100%;
  }
  & .close-banner {
    position: absolute;
    right: 0;
    top: 0;
  }
`

export const OfferingInfo = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  height: 160px;
  margin-top: 5px;
  overflow: hidden;
  position: relative;
  width: 100%;
  & .main-component-icon {
    left: 5px;
    position: absolute;
    top: 5px;
  }
  & .open-modal {
    background-color: ${salmon};
    border-radius: 50%;
    right: 5px;
    padding: 0;
    position: absolute;
    top: 5px;
    z-index: 1;
  }
  & .date-ec {
    display: block;
    width: 100%;
  }
  & .overlay {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    padding: 10px 10px 15px 10px;
    position: absolute;
    top: 0;
    width: 100%;
    & button {
      align-items: center;
      display: flex;
      justify-content: center;
    }
    & h3 {
      padding: 30px 0 0 0;
    }
  }
  &.big-slide {
    height: 300px;
    & .overlay {
      background-color: rgba(0, 0, 0, 0.3);
    }
    & .open-modal {
      right: 15px;
      top: 10px;
    }
  }
`

export const CustomizeComponentContainer = styled.div`
  width: 100%;
  & svg.cursor {
    position: relative;
    top: -2px;
  }
  & .make-it-main {
    float: right;
    margin: 0;
    & svg {
      position: relative;
      top: -3px;
    }
  }
  & input.form-control {
    display: inline-block;
    width: 250px;
  }
`

export const RSVPtitle = styled.h1`
  @media (min-width: 576px) {
    background-image: none !important;
  }
  @media (max-width: 575px) {
    &:not(.no-background) {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 20px 0 0 0;
      width: 100% !important;
    }
  }
`

export const MainProviderImage = styled.div`
  align-items: flex-start;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
  display: flex;
  height: 160px;
  justify-content: center;
  width: 100%;
  & h3 {
    color: white;
    font-size: 30px;
    padding: 20px 0 0 0;
    text-shadow: 0 0 25px rgba(0, 0, 0, 1);
  }
  &.big-slide {
    height: 300px;
    & h3 {
      font-size: 70px;
    }
  }
  & h4 {
    align-items: center;
    color: white;
    display: flex;
    font-size: 22px;
    font-weight: 600;
    height: 66%;
    padding: 0 20px;
    text-shadow: 0 0 30px black;
  }
`

export const SegmentTitle = styled.h2`
  text-align: center;
  &.default {
    margin: 3rem 0 0 0;
  }
  &.gold {
    background-color: var(--gold);
    color: var(--brown);
    font-size: clamp(30px, 5vw, 50px);
    margin: 0 0 clamp(3rem, 5vw, 5rem) 0;
    padding: clamp(2.5rem, 5vw, 4rem) 1rem clamp(4.5rem, 5vw, 6rem) 1rem;
    position: relative;
    &::after {
      background-size: cover;
      background: url('${wave}') no-repeat top;
      bottom: 0;
      content: '';
      height: 50px;
      left: 0;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
  }
`
