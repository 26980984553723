import styled from 'styled-components'

import { darkGray } from '../../../styles/themeColors'

export const ImgCont = styled.div`
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 100%;
`

export const Form1st100Groups = styled.div`
  color: ${darkGray};
  min-width: 420px;
  max-width: 420px;
  width: 96%;
  & h3 {
    font-size: 1.2rem;
    font-weight: 700;
  }
  @media (max-width: 850px) {
    min-width: 360px;
    max-width: 360px;
    padding: 2rem !important;
  }
  @media (max-width: 767px) {
    min-width: 100%;
    max-width: 100%;
  }
`

export const TopSearchesContainer = styled.div`
  padding: 5rem 0;
  & a {
    color: white;
    font-size: 1.6rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
  }
  & .searches-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 10px;
    margin: 0 auto;
    max-width: 1280px;
  }
  & .card-search {
    border-radius: 10px;
    margin: 0 auto 20px auto;
    max-width: 100%;
    position: relative;
    width: 400px;
    & img {
      border-radius: 10px;
    }
    & span {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 100%;
      padding: 0 15px;
      position: absolute;
      right: 0;
      text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
      top: 0;
      width: 100%;
    }
    &::before {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    padding: 1rem 0;
    & .searches-grid {
      grid-gap: 0px;
    }
    & .card-search {
      margin: 0 auto 7px auto;
      & img {
        height: 200px;
        object-fit: cover;
        width: 100%;
      }
    }
  }
`

export const InstagramContainer = styled.div`
  padding: 4rem 1.5rem;
  width: 100%;
  & .posts {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    max-width: 950px;
    & .instagram-post {
      margin: 2px;
      & img {
        height: 309px;
        object-fit: cover;
        user-select: none;
        width: 309px;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 3rem 0.3rem;
    & .posts {
      & .instagram-post {
        & img {
          height: 180px;
          width: 180px;
        }
      }
    }
  }
  @media (max-width: 440px) {
    & .posts {
      & .instagram-post {
        & img {
          height: 170px;
          width: 170px;
        }
        &:last-child {
          // just to keep have 2 posts per row on mobile or else it will 1 row with only 1 post
          display: none;
        }
      }
    }
  }
`

export const VideoBGContainer = styled.section`
  height: 96vh;
  overflow: hidden;
  position: relative;
  width: 100%;
  video {
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: relative;
    width: 100%;
    z-index: 0;
  }
  .overlay {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    height: 100%;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  .wave {
    bottom: -1px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
  }
  .video-bg-volume {
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 4;
  }
  .children {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;
    &.hero {
      * {
        color: white;
        text-align: center;
      }
      button {
        font-size: 1.2rem;
        margin: 6rem 0 0 0;
        width: 320px;
      }
      h2 {
        font-size: 1.3rem;
        font-weight: 700;
      }
      h1 {
        font-size: 6rem;
      }
      @media (width < 800px) {
        h1 {
          font-size: 3.8rem;
        }
        h2 {
          font-size: 1.1rem;
        }
        button {
          margin: 4rem 0 0 0;
        }
      }
      @media (width < 500px) {
        button {
          font-size: 1rem;
          margin: 3rem 0 0 0;
          width: 250px;
        }
        h1 {
          font-size: 3rem;
        }
        h2 {
          font-size: 1rem;
        }
      }
      @media (width < 500px) {
        h1 {
          line-height: 0.8;
        }
      }
    }
  }
  .credit {
    bottom: 70px;
    color: rgba(255, 255, 255, 0.3);
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    right: 1.5rem;
    z-index: 3;
  }
  @media (width < 1400px) {
    .credit {
      bottom: 50px;
    }
  }
  @media (width < 500px) {
    height: 500px;
    .video-bg-volume {
      top: 25px;
    }
    .credit {
      bottom: 25px;
      font-size: 12px;
      right: 0.5rem;
    }
  }
`
