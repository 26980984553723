import React, { useState, useRef, useEffect } from 'react'
import _map from 'lodash/fp/map'
import get from 'lodash/fp/get'
import flow from 'lodash/fp/flow'
import uniq from 'lodash/fp/uniq'
import { Wrapper, Status } from '@googlemaps/react-wrapper'

import { checkEnv, getCoords } from '../../../common'
import {
  EventComponent,
  GoogleMap,
  MapProps,
  GoogleLatLng,
  GoogleMarkerOptions,
  GoogleMarker,
} from '../../../types'
import { MAPS_PROD_API_KEY, MAPS_DEV_API_KEY } from '../../../common/constants'

const Marker: React.FC<GoogleMarkerOptions> = options => {
  const [marker, setMarker] = useState<GoogleMarker>()

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker())
    }
    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [marker])

  useEffect(() => {
    if (marker) {
      marker.setOptions(options)
    }
  }, [marker, options])

  return null
}

const RenderMap: React.FC<MapProps> = ({
  children,
  setPins,
  coords,
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<GoogleMap>()

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}))
    }

    if (map) {
      const pinsArray: GoogleLatLng[] = []
      coords.forEach(coord => {
        const [lat, lng] = getCoords(coord)
        pinsArray.push(new window.google.maps.LatLng(lat, lng))
      })

      // draw pins on map
      setPins(pinsArray)

      // zoom to fit all pins
      const latlngbounds = new window.google.maps.LatLngBounds()
      pinsArray.forEach(pin => {
        latlngbounds.extend(pin)
      })
      map.fitBounds(latlngbounds)
    }
  }, [ref, map, setPins, coords])

  if (map) {
    map.setOptions(options)
  }

  const renderChild = (child: React.ReactNode) => {
    if (React.isValidElement<{ map: GoogleMap }>(child)) {
      return React.cloneElement(child, {
        ...child.props,
        map,
      })
    }
    return null
  }

  return (
    <div style={{ height: '400px', flexGrow: 1 }} ref={ref}>
      {React.Children.map(children, renderChild)}
    </div>
  )
}

export const RSVPmap: React.FC<{ components: EventComponent[] }> = ({
  components,
}) => {
  const { develop } = checkEnv()
  const [coords, setCoords] = useState<string[]>([])
  const [pins, setPins] = useState<GoogleLatLng[]>([])
  const apiKey = develop ? MAPS_DEV_API_KEY : MAPS_PROD_API_KEY

  const render = (status: Status) => {
    return <div className="text-center">{status}</div>
  }

  useEffect(() => {
    if (coords.length === 0) {
      setCoords(flow(_map(get('offering.provider.coord')), uniq)(components))
    }
  }, [components, coords.length])

  if (components.length <= 0) return null
  return (
    <section className="map-section mt-5">
      <h3 className="py-2 py-lg-4 fz-30 mb-3 text-center">Map Your Stay</h3>
      <Wrapper apiKey={apiKey} render={render}>
        <RenderMap setPins={setPins} coords={coords}>
          {pins.map(latLng => (
            <Marker key={JSON.stringify(latLng)} position={latLng} />
          ))}
        </RenderMap>
      </Wrapper>
    </section>
  )
}
