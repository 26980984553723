import styled from 'styled-components'

import { lightGray, salmon, darkGray } from '../../styles/themeColors'

export const RSVPCard = styled.article`
  background-color: ${lightGray};
  border-radius: 6px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1) inset;
  margin: 20px auto;
  max-width: 300px;
  padding: 0 10px 10px 10px;
  position: relative;
  text-align: center;
  width: 100%;
  & .default-user-profile,
  & .user-profile {
    box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);
    margin-top: -20px;
  }
  // custom Swiper colors
  & .swiper-button-prev,
  & .swiper-button-next {
    transform: scale(0.6);
  }
  & .swiper-pagination-bullets {
    bottom: 0 !important;
    & .swiper-pagination-bullet {
      background: white;
      opacity: 0.5;
      transform: scale(0.7);
      &.swiper-pagination-bullet-active {
        background: ${salmon};
        opacity: 1;
      }
    }
  }
  & .default-card-info {
    background-color: ${darkGray};
    border-radius: 6px;
    margin: 10px auto 0 auto;
    padding: 17px 0;
    text-align: center;
    width: 100%;
  }

  // end custom Swiper colors
  @media (max-width: 500px) {
    margin: 20px 5px;
    max-width: 400px;
  }
`

export const RSVPcontainer = styled.div`
  margin-top: -1px;
  padding: 0;
  position: relative;
  & h1 {
    color: white;
    font-size: 90px;
    padding-bottom: 15px;
    margin: 0 auto;
    position: relative;
    text-align: center;
    text-shadow: 0 0 10px rgba(0, 0, 0, 1);
    width: fit-content;
  }
  & .rsvp-actions {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    & .title-text {
      align-items: center;
      display: flex;
      font-size: 16px;
      justify-content: center;
      min-height: 30px;
      width: 100%;
    }
    & button {
      background-color: white;
      border: 2px solid ${darkGray};
      border-radius: 3px;
      font-size: 18px;
      margin: 10px;
      padding: 5px 20px 5px 45px;
      position: relative;
      & svg {
        background-color: ${darkGray};
        border-radius: 50%;
        padding: 2px;
        position: absolute;
        left: 12px;
        top: 8px;
      }
      &.active {
        background-color: ${salmon};
        border: 2px solid ${salmon};
        color: white;
        & svg {
          background-color: white;
          color: ${salmon} !important;
        }
      }
    }
  }
`

export const RoomsContainer = styled.div`
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 20px 0 0 0;
  width: 100%;
  @media (max-width: 991px) {
    justify-content: space-evenly;
    width: 100%;
  }
`
