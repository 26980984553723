import React from 'react'
import capitalize from 'lodash/fp/capitalize'

import { formatTime } from '../common'
import { PackageAddons } from '../types'

export const PackageCustomizations: React.FC<{
  customizations: PackageAddons[]
}> = ({ customizations }) => {
  if (customizations.length <= 0) return <></>
  return (
    <div className="w-100 py-2 px-3 px-lg-0">
      <p className="mb-1 customizations-title">Schedule</p>
      <ul>
        {customizations.map(el => {
          const event = capitalize(el.offeringAddOn.label || '--')
          const location = capitalize(el.location || '--')
          const time = formatTime(el.time) || '--'
          const duration = el.offeringAddOnOption.label || '--'
          return (
            <li key={el.id}>
              {event} at {location} - {time} ({duration})
            </li>
          )
        })}
      </ul>
    </div>
  )
}
