import React, { useEffect } from 'react'
import getOr from 'lodash/fp/getOr'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { useQueryParams } from '../../common'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { GET_EVENT_FEEDS } from '../../graphql'
import { MyEvent, Post, Event } from '../../types'
import {
  setCurrentEventId,
  setPinnedPostsCount,
} from '../../features/siteSlice'

import Composer from './Composer'
import PostComp from './Post'
import { PinnedPosts } from './components'

interface IFeedProps {
  myEvents: MyEvent[]
  noGuests: boolean
  otherEvents?: Event[]
  eventId?: string
  className?: string
}

const Feed: React.FC<IFeedProps> = ({
  myEvents,
  noGuests,
  otherEvents = [],
  eventId,
  className = '',
}) => {
  const { eventId: eventInUrl, scroll } = useQueryParams()
  const dispatch = useAppDispatch()
  const { currentEventId, pinnedPostsCount } = useAppSelector(
    state => state.site
  )

  // we use this component in the invitations page so we need to be able to load different event data
  const eventIdToUse = eventId || currentEventId

  const { data } = useQuery(GET_EVENT_FEEDS, {
    variables: { eventIds: [eventIdToUse] },
    fetchPolicy: 'cache-and-network',
  })
  const posts = getOr<Post[]>([], 'feed.entries', data)

  const allEvents = [
    ...myEvents,
    ...otherEvents.filter(el => el.guestProfile.canPost), // to remove events where the canPost permission is false
  ]
  const selectedEvent = allEvents.find(event => event.id === eventIdToUse)

  // in case there are more than 2 pinned posts, we only show the first 2
  const pinnedPosts = posts?.filter(post => post.pinned === true).slice(0, 2)

  // we remove the pinned posts from the posts array
  const filteredPosts = posts?.filter(post => {
    return !pinnedPosts?.some(pinnedPost => pinnedPost.id === post.id)
  })

  useEffect(() => {
    // to not dispatch the same value multiple times
    if (pinnedPostsCount !== pinnedPosts.length) {
      dispatch(setPinnedPostsCount(pinnedPosts.length))
    }

    // disable the eslint warning for the next line to avoid infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinnedPosts.length])

  useEffect(() => {
    if (!eventInUrl || currentEventId === eventInUrl) return
    dispatch(setCurrentEventId(eventInUrl))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventInUrl, currentEventId])

  useEffect(() => {
    const isValidPostCardId = (str: string): boolean => {
      const regex =
        /^post-card-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
      return regex.test(str)
    }
    if (!isValidPostCardId(scroll)) return // if scroll has another value than the post card id, we don't scroll here

    const scrollToPostCard = () => {
      const postCard = document.querySelector(`#${scroll}`)
      if (!postCard) return

      const top = postCard.getBoundingClientRect().top - 65
      window.scrollTo({
        behavior: 'smooth',
        top,
      })
    }

    // I'm not sure why I can't use a listener on DomContentLoaded or useLayoutEffect
    // so I'm using a timeout to wait for the post card to be rendered before scrolling
    const timeoutId = setTimeout(scrollToPostCard, 1000)
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeoutId)
  }, [scroll])

  return (
    <div className={`feed-container ${className}`}>
      {selectedEvent && <Composer event={selectedEvent} />}

      {myEvents.length > 0 && noGuests && (
        <div className="text-center py-5">
          <p className="fz-16 mb-0">
            Looks like you don’t have any guests yet. Invite some and start the
            conversation!
          </p>
          <p className="fwsb fz-18">{myEvents[0].name}</p>
          <Link to={`/event/${getOr('', 'id', myEvents[0])}/guests`}>
            <Button className="fz-24 px-4 shadow-sm">Invite Guests</Button>
          </Link>
        </div>
      )}

      <PinnedPosts posts={pinnedPosts} events={[...myEvents, ...otherEvents]} />

      {filteredPosts.map(post => (
        <PostComp
          key={post.id}
          post={post}
          events={[...myEvents, ...otherEvents]}
        />
      ))}
    </div>
  )
}
export default Feed
