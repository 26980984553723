import React, { useRef, useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import { TbMoodKid } from 'react-icons/tb'

import { RSVPtitle } from '../style'
import { defaultIntro } from '../rsvpFunctions'
import user from '../../../../assets/images/rsvp/user.jpg'
import { IInvitationBody } from '../../../../types'
import {
  VimeoMobile,
  getCheckInOut,
  getImageUrl,
  replaceLineBreaksWithBR,
} from '../../../../common'

import { FloatingImage, Template2Container } from './style'

export const Template2: React.FC<
  Pick<
    IInvitationBody,
    'mainProvider' | 'event' | 'plusOne' | 'kidsAllowed'
  > & { guest?: IInvitationBody['guest'] }
> = ({ event, mainProvider, guest, children, kidsAllowed }) => {
  const [imgHelper, setImgHelper] = useState('10px')
  const bgImage = getImageUrl(mainProvider.mainImage, '1300x600')
  const imgRef = useRef<HTMLImageElement>(null)
  const userImgRef = useRef<HTMLImageElement>(null)
  const { host } = event

  useEffect(() => {
    const handleScroll = () => {
      if (imgRef.current && userImgRef.current) {
        const { scrollY } = window
        const imgBottom = imgRef.current.offsetTop + imgRef.current.offsetHeight
        const rect = imgRef.current.getBoundingClientRect()

        if (scrollY >= imgBottom - 100) {
          const newLeft = `${rect.left + 10}px`
          // to not trigger unnecessary re-renders
          if (newLeft !== imgHelper) {
            setImgHelper(newLeft)
          }
          if (!userImgRef.current.classList.contains('near-top')) {
            userImgRef.current.classList.add('near-top')
          }
        } else {
          const newLeft = '10px'
          // to not trigger unnecessary re-renders
          if (newLeft !== imgHelper) {
            setImgHelper(newLeft)
          }
          if (userImgRef.current.classList.contains('near-top')) {
            userImgRef.current.classList.remove('near-top')
          }
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [imgRef, userImgRef, imgHelper])

  const replaceAmper = (str?: string) => {
    if (!str) return ''
    return str.replace(/(&\s*)|(\s*&)/g, '')
  }
  const brideFirstName = event.brideFirstName || replaceAmper(host.firstName)
  const groomFirstName = event.groomFirstName || replaceAmper(host.lastName)
  const { groomLastName, brideLastName } = event

  return (
    <Template2Container className="container">
      <div className="rsvp-bg">
        <img
          src={bgImage}
          alt={mainProvider.name}
          ref={imgRef}
          className="bg"
        />
        <RSVPtitle className="template2-title font-lf no-background">
          {event.name}
        </RSVPtitle>

        <FloatingImage
          alt="User"
          ref={userImgRef}
          className="large"
          src={event.mainImage || user}
          style={{ left: imgHelper }}
        />

        {event.video && (
          <div className="template-extra-item">
            <VimeoMobile muted url={event.video} />
          </div>
        )}
        {!event.video && (
          <div className="template-extra-item default">
            <div className="default">
              <div className="frame-white">
                <div className="frame">
                  <span className="extra">
                    You're invited to celebrate the love of
                  </span>

                  <div className="couple">
                    <span className="bride_fn">{brideFirstName}</span>
                    {brideLastName && (
                      <span className="bride_ln">{brideLastName}</span>
                    )}
                    <span>
                      <span className="and">&</span>
                      <span className="line" />
                    </span>
                    <span className="groom_fn">{groomFirstName}</span>
                    {groomLastName && (
                      <span className="groom_ln">{groomLastName}</span>
                    )}
                  </div>

                  <div className="extra px-2">
                    <span className="d-block fwb mt-1 no-under">
                      {mainProvider.name}
                    </span>
                    <p className="fz-11 fwn mb-0">{mainProvider?.address}</p>
                    <span className="fz-11 d-block fwb">
                      {mainProvider.location}
                    </span>
                    <span className="fz-16 fwsb py-2 d-block">
                      {getCheckInOut({
                        date: event.date,
                        dateFormat: 'MMM DD, YYYY',
                        duration: event.duration,
                      })}
                    </span>

                    {kidsAllowed && (
                      <span className="d-block">
                        Children permitted <TbMoodKid size={20} />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Card
        body
        style={{ maxWidth: '700px' }}
        className="mx-auto shadow-sm pb-2 text-center"
      >
        <p className="fz-24 fwb">Will you join us?</p>
        <p className="pt-1 px-0 px-sm-5">
          {guest && (
            <>
              Hey {guest?.firstName}! <br />
            </>
          )}
          {event.description
            ? replaceLineBreaksWithBR(event.description)
            : defaultIntro()}
        </p>
        {children}
      </Card>
    </Template2Container>
  )
}
