import React, { useState, useRef, useEffect, useCallback } from 'react'
import {
  MdPause,
  MdPlayArrow,
  MdOutlinePlayCircleFilled,
  MdOutlinePauseCircleFilled,
  MdFullscreen,
  // MdFullscreenExit,
} from 'react-icons/md'

import user from '../../../../assets/images/rsvp/user.jpg'
import { IInvitationBody } from '../../../../types'
import { salmon } from '../../../../styles/themeColors'
import { RSVPtitle } from '../style'
import { getImageUrl, replaceLineBreaksWithBR } from '../../../../common'
import { defaultIntro } from '../rsvpFunctions'

import {
  FloatingImage,
  ParallaxBox,
  Template3Children,
  Template3Container,
  VideoControls,
  VideoRSVP,
  VideoRSVPContainer,
} from './style'

const VideoPlayer = React.memo(
  ({
    videoUrl,
    posterUrl,
    onPlayingChange,
  }: {
    videoUrl: string
    posterUrl: string
    onPlayingChange?: (isPlaying: boolean) => void
  }) => {
    const videoRef = useRef<HTMLVideoElement>(null)
    const [isPlaying, setIsPlaying] = useState(false)
    const [progress, setProgress] = useState(0)
    const [duration, setDuration] = useState(0)

    useEffect(() => {
      const video = videoRef.current
      if (!video) return undefined

      const updateDuration = () => setDuration(video.duration || 0)
      video.addEventListener('loadedmetadata', updateDuration)

      return () => {
        video.removeEventListener('loadedmetadata', updateDuration)
      }
    }, [])

    useEffect(() => {
      const video = videoRef.current
      if (!video) return undefined

      const handleFullscreenChange = () => {
        video.classList.toggle('video-cover')
      }
      video.addEventListener('fullscreenchange', handleFullscreenChange)

      return () => {
        video.removeEventListener('fullscreenchange', handleFullscreenChange)
      }
    }, [])

    const formatTime = useCallback(
      (time: number) =>
        `${Math.floor(time / 60)}:${String(Math.floor(time % 60)).padStart(
          2,
          '0'
        )}`,
      []
    )

    const togglePlayPause = useCallback(() => {
      const video = videoRef.current
      if (!video) return

      if (isPlaying) {
        video.pause()
      } else {
        video.play()
      }
      const nextPlayingState = !isPlaying
      setIsPlaying(nextPlayingState)
      onPlayingChange?.(nextPlayingState)
    }, [isPlaying, onPlayingChange])

    const handleProgressChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const video = videoRef.current
        if (!video) return

        const newTime = parseFloat(e.target.value)
        video.currentTime = newTime
        setProgress(newTime)
      },
      []
    )

    const handleTimeUpdate = useCallback(() => {
      const video = videoRef.current
      if (!video) return
      setProgress(video.currentTime)
    }, [])

    const toggleFullScreen = useCallback(() => {
      if (!videoRef.current) return
      if (!document.fullscreenElement) {
        videoRef.current.requestFullscreen()
      }
    }, [])

    return (
      <>
        <VideoRSVP
          loop
          controls={false}
          ref={videoRef}
          src={videoUrl}
          playsInline
          className="video-cover"
          preload="metadata"
          poster={posterUrl}
          onTimeUpdate={handleTimeUpdate}
          onPause={() => {
            setIsPlaying(false)
            onPlayingChange?.(false)
          }}
          onPlay={() => {
            setIsPlaying(true)
            onPlayingChange?.(true)
          }}
        />

        {!isPlaying && (
          <MdOutlinePlayCircleFilled
            size={60}
            color={salmon}
            className="play-icon cursor"
            onClick={togglePlayPause}
            aria-label="Play video"
          />
        )}
        {isPlaying && (
          <MdOutlinePauseCircleFilled
            size={60}
            color={salmon}
            className="pause-icon cursor"
            onClick={togglePlayPause}
            aria-label="Pause video"
          />
        )}

        <VideoControls className="video-controls">
          <button
            onClick={togglePlayPause}
            type="button"
            aria-label={isPlaying ? 'Pause video' : 'Play video'}
          >
            {isPlaying ? (
              <MdPause size={24} color="white" />
            ) : (
              <MdPlayArrow size={24} color="white" />
            )}
          </button>
          <input
            type="range"
            min="0"
            max={duration || 1}
            value={progress}
            onChange={handleProgressChange}
          />
          <span>
            {formatTime(progress)} / {formatTime(duration)}
          </span>
          <MdFullscreen
            size={24}
            color="white"
            onClick={toggleFullScreen}
            aria-label="Fullscreen"
            className="cursor fullscreen-icon"
          />
        </VideoControls>
      </>
    )
  }
)

export default VideoPlayer

export const Template3: React.FC<
  Pick<IInvitationBody, 'event' | 'mainProvider'> & {
    guest?: IInvitationBody['guest']
  }
> = ({ event, guest, mainProvider, children }) => {
  const [imgHelper, setImgHelper] = useState('5%')
  const videoContainerRef = useRef<HTMLDivElement>(null)
  const userImgRef = useRef<HTMLImageElement>(null)
  const bgImage = getImageUrl(mainProvider.mainImage, '1366x768')
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const videoContainer = videoContainerRef.current
      const eventImg = userImgRef.current
      if (videoContainer && eventImg) {
        const { scrollY } = window
        const videoBottom =
          videoContainer.offsetTop + videoContainer.offsetHeight
        const rect = videoContainer.getBoundingClientRect()

        if (scrollY >= videoBottom - 100) {
          const newLeft = `${rect.left + 10}px`
          // to not trigger unnecessary re-renders
          if (newLeft !== imgHelper) {
            setImgHelper(newLeft)
          }
          if (!eventImg.classList.contains('near-top')) {
            eventImg.classList.add('near-top')
          }
        } else {
          const newLeft = '5%'
          // to not trigger unnecessary re-renders
          if (newLeft !== imgHelper) {
            setImgHelper(newLeft)
          }
          if (eventImg.classList.contains('near-top')) {
            eventImg.classList.remove('near-top')
          }
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [videoContainerRef, userImgRef, imgHelper])

  const showHideElement = !isPlaying ? 'visible' : 'invisible'

  const texts = event.description?.split(/-{2,}/).map(item => item.trim())
  const parallaxText = texts?.[0]
    ? replaceLineBreaksWithBR(texts[0].slice(0, 80))
    : defaultIntro()
  const pinkSectionText = texts?.[1]
    ? replaceLineBreaksWithBR(texts[1])
    : `We've chosen Hotel Santa Marta as the hub for our group stay. It is a beautiful secluded beachfront hotel on the Mediterranean coast, just down the road from our venue. We'd love for everyone to stay there!`

  return (
    <Template3Container>
      <VideoRSVPContainer
        ref={videoContainerRef}
        className={isPlaying ? 'show-controls' : 'hide-controls'}
      >
        <RSVPtitle
          className={`pt-2 pt-md-5 template3-title font-lf ${showHideElement}`}
        >
          {event.name}
        </RSVPtitle>

        <VideoPlayer
          videoUrl={event.video as string}
          posterUrl={event.videoPreview as string}
          onPlayingChange={setIsPlaying}
        />

        <FloatingImage
          alt="User"
          ref={userImgRef}
          src={event.mainImage || user}
          className={`large ${showHideElement}`}
          style={{ left: imgHelper }}
        />
      </VideoRSVPContainer>

      <div className="py-3 py-md-5">
        <span className="w-100 mx-auto text-center d-block fz-30 font-poppins fwm mw-1200 text-brown">
          Will you join us?
        </span>
      </div>
      <ParallaxBox style={{ backgroundImage: `url(${bgImage})` }}>
        <div className="p-0 m-0 font-poppins fz-50 fweb text-center custom-text mw-1300">
          {guest && (
            <span className="w-100 d-block pb-3 pb-5">
              Hey {guest?.firstName}! <br />
            </span>
          )}
          <p className="m-0 p-0">{parallaxText}</p>
        </div>
      </ParallaxBox>
      <Template3Children>
        <div className="mw-1300 mx-auto text pt-0 pb-3 py-md-5 px-3 font-playfair fwm">
          {pinkSectionText}
        </div>
        {children}
      </Template3Children>
    </Template3Container>
  )
}
