import styled from 'styled-components'

import { lightSalmon, salmon } from '../../../../styles/themeColors'

export const Template1Container = styled.div`
  position: relative;
  .rsvp-bg {
    display: inline-block;
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    & img {
      height: auto;
      width: 100%;
    }
  }
`

export const Template2Container = styled.div`
  width: 100%;
  .template2-title {
    margin: 0 0 0 0;
  }
  .rsvp-bg {
    display: inline-block;
    height: auto;
    margin-bottom: 150px;
    position: relative;
    top: -1.5rem;
    width: 100%;
    img.bg {
      height: auto;
      width: 100%;
    }
    .font-lf {
      left: 0;
      position: absolute;
      top: 1.5rem;
      width: 100%;
    }
    .template-extra-item {
      bottom: -150px;
      position: absolute;
      right: 50px;
      .default {
        align-items: center;
        background-color: #edbda7;
        display: flex;
        height: 476px;
        justify-content: center;
        margin: 0 auto;
        width: 300px;
        .frame-white {
          align-items: center;
          background-color: white;
          display: flex;
          height: 446px;
          justify-content: center;
          width: 269px;
        }
        .frame {
          background-color: white;
          border: 2px solid #a06239;
          color: #a06239;
          display: flex;
          flex-direction: column;
          height: 426px;
          justify-content: space-between;
          padding: 10px 5px;
          text-align: center;
          width: 249px;
          .extra {
            font-size: 13px;
          }
          .couple {
            font-family: 'Cormorant Garamond', serif;
            font-size: 36px;
            font-weight: 500;
            margin: 0 auto;
            width: fit-content;
            > span {
              display: block;
              .and {
                background-color: white;
                display: inline-block;
                padding: 0 5px;
                position: relative;
                width: fit-content;
                z-index: 1;
              }
              .line {
                background-color: #a06239;
                display: block;
                height: 1px;
                position: relative;
                top: -27px;
                width: 100%;
              }
            }
            .bride_fn,
            .bride_ln,
            .groom_fn,
            .groom_ln {
              line-height: 1.1;
            }
            .bride_ln,
            .groom_ln {
              font-size: 24px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1100px) {
    .rsvp-bg {
      margin-bottom: 240px;
      .template-extra-item {
        bottom: -240px;
        right: 10px;
      }
    }
  }
  @media (max-width: 900px) {
    .rsvp-bg {
      margin-bottom: 540px;
      .template-extra-item {
        bottom: -540px;
        right: 0;
        width: 100%;
        .default {
          position: relative;
          top: -30px;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .rsvp-bg {
      h1 {
        font-size: 60px;
      }
    }
  }
  @media (max-width: 450px) {
    .template2-title {
      margin: 1rem 0 0 0;
    }
    .rsvp-bg {
      h1 {
        font-size: 50px;
      }
    }
  }
`

export const FloatingImage = styled.img`
  bottom: -160px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  height: 300px;
  left: 10px;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  transition: width 0.3s ease, height 0.3s ease;
  width: 300px;
  z-index: 4;
  &.near-top {
    bottom: unset;
    height: 100px;
    position: fixed;
    transition: width 0.3s ease, height 0.3s ease;
    top: 5px;
    width: 100px;
  }
  @media (max-width: 900px) {
    height: 150px;
    width: 150px;
  }
  @media (max-width: 700px) {
    bottom: -10px;
    height: 100px;
    width: 100px;
  }
  @media (max-width: 450px) {
    height: 80px;
    width: 80px;
  }
`

export const Template3Container = styled.div`
  position: relative;
  width: 100%;
`

export const Template3Children = styled.div`
  background-color: ${lightSalmon};
  color: #56483a;
  padding: 1rem 0 2rem 0;
  text-align: center;
  width: 100%;
  div {
    font-size: clamp(24px, 5vw, 50px);
  }
  span,
  h6,
  button {
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 500;
  }
  .btn-primary {
    background-color: white;
    color: ${salmon};
    font-weight: 500;
    margin: 1rem 0;
    padding: 0.5rem 2rem;
  }
  span {
    color: #56483a;
    font-size: 12px !important;
    font-weight: 500;
  }
  h6 {
    margin: 1rem auto 0 auto !important;
    img {
      display: none;
    }
  }
`

export const VideoRSVP = styled.video`
  height: 99%;
  width: 100%;
  &.video-cover {
    object-fit: cover;
    object-position: center;
  }
`

export const VideoRSVPContainer = styled.div`
  aspect-ratio: 16/9;
  margin: 0 0 -14px 0;
  position: relative;
  width: 100%;
  &.hide-controls {
    .video-controls {
      display: none;
    }
  }
  .play-icon,
  .pause-icon {
    bottom: 42%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  .pause-icon {
    display: none;
  }
  &:hover {
    .pause-icon {
      display: block;
    }
  }
  .template3-title {
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
  }
  @media (width <= 700px) {
    .template3-title {
      font-size: 60px;
    }
  }
  @media (width <= 500px) {
    .template3-title {
      font-size: 40px;
      padding: 10px 0;
    }
    .play-icon,
    .pause-icon {
      bottom: 24%;
      padding: 10px;
    }
  }
`

export const VideoControls = styled.div`
  align-items: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  color: white;
  bottom: 8px;
  display: flex;
  justify-content: space-evenly;
  left: 0;
  padding: 2rem 10px 1rem 10px;
  position: absolute;
  width: 100%;
  button {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    width: 30px;
  }
  span {
    text-align: center;
    width: 100px;
  }
  input {
    accent-color: ${salmon};
    width: calc(100% - 170px);
  }
  @media (width >= 1400px) {
    bottom: 10px;
  }
  @media (width >= 2500px) {
    bottom: 15px;
  }
  @media (width <= 1100px) {
    bottom: 6px;
  }
  @media (width <= 800px) {
    bottom: 5px;
    padding: 1rem 5px 10px 0;
  }
`

export const ParallaxBox = styled.div`
  align-items: center;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  min-height: 600px;
  padding: 1rem;
  width: 100%;
  .custom-text {
    color: white;
    font-size: clamp(30px, 5vw, 50px);
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.9);
    width: 100%;
  }
`
