import React from 'react'
import get from 'lodash/fp/get'
import find from 'lodash/fp/find'
import Button from 'react-bootstrap/Button'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'

import { RSVP_PREVIEW_DATA } from '../../graphql'
import { SimpleLoader } from '../../components'
import { Common404 } from '../../components/Error404'
import Arrow from '../../assets/images/icons/arrow.svg'
import { useCurrentUser } from '../../common'
import {
  MyEvent,
  EventComponent,
  EventComponentStatus,
  Template,
} from '../../types'

import { RSVPcontainer } from './style'
import { EventSegments, RSVPmap } from './components'
import { exampleData } from './RSVPpreviewExampleData'
import { Template1, Template2, Template3 } from './components/templates'

const templates = {
  template_1: Template1,
  template_2: Template2,
  template_3: Template3,
}

export const RSVPpreview: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>()
  const { firstName, lastName } = useCurrentUser()
  const variables = { id: eventId }
  const { data, loading } = useQuery(RSVP_PREVIEW_DATA, { variables })
  const event: MyEvent = get('me.myEvents[0]', data)

  if (loading) return <SimpleLoader />
  else if (!data) {
    return (
      <Common404 text="No preview data" link={`/event/${eventId}/settings`} />
    )
  }

  const { Available } = EventComponentStatus
  const paidComponents = event.components.filter(el => el.status === Available)
  if (paidComponents.length <= 0) {
    return <Common404 text="Location TBD" hideLink />
  }

  const mainComponent =
    find({ main: true }, paidComponents) || paidComponents[0]
  const mainProvider = mainComponent?.offering.provider

  const { mainImage } = event
  const renderTemplate = (children: React.ReactElement) => {
    const eventData = {
      id: event.id,
      date: mainComponent.date,
      duration: mainComponent.duration,
      name: event.name,
      description: event.description,
      mainImage,
      video: event.video,
      videoPreview: event.videoPreview,
      host: {
        firstName,
        lastName,
        avatarUrl: '',
      },
      brideFirstName: event.brideFirstName,
      brideLastName: event.brideLastName,
      groomFirstName: event.groomFirstName,
      groomLastName: event.groomLastName,
    }
    const eventTemplate = event.invitationTemplate?.template as Template
    const props = {
      event: eventData,
      guest: {
        firstName: 'firstName',
        lastName: 'lastName',
        email: 'example@gmail.com',
        status: 'accepted',
      },
      plusOne: 2,
      mainProvider,
      kidsAllowed: false,
    }
    const SelectedTemplate = templates[eventTemplate] || Template1
    return <SelectedTemplate {...props}>{children}</SelectedTemplate>
  }

  return (
    <RSVPcontainer className="container-fluid">
      {renderTemplate(
        <>
          <Button className="rounded-pill">View Event</Button>
          <h6
            className="mx-auto mt-4 position-relative fwsb"
            style={{ maxWidth: '350px' }}
          >
            Book your room, get updates
            <br />& chat with the group
            <img
              src={Arrow}
              alt="->"
              className="arrow-rsvp h-auto position-absolute"
              style={{
                width: '40px',
                transform: 'scaleX(-1) rotate(-40deg)',
                top: '-28px',
                right: '65px',
              }}
            />
          </h6>
        </>
      )}

      {/** if there are paid components the default components are replaced by the paid components */}
      <EventSegments
        eventId={eventId}
        eventName={event.name}
        guest={{
          firstName: 'firstName',
          lastName: 'lastName',
          email: 'email@gmail.com',
          status: 'accepted',
        }}
        eventComponents={
          paidComponents.length > 0
            ? paidComponents
            : (exampleData.eventComponents as EventComponent[])
        }
      />
      {/** just to remove the Maybe type */}
      <RSVPmap
        components={
          paidComponents.length > 0
            ? paidComponents
            : (exampleData.eventComponents as EventComponent[])
        }
      />
    </RSVPcontainer>
  )
}
